import React from "react";
import '../../styles/layout/footerNotFound.scss';
import enMessages from "../../i18n/navbar/en.json";
import ruMessages from "../../i18n/navbar/ru.json";

const messages = {
    en: enMessages,
    ru: ruMessages
};

const currentYear = new Date().getFullYear();

const FooterNotFound = ({locale}) => (
    <footer className="FooterNotFound">
        <div className="container FooterNotFound-Container">
            <div className="FooterNotFound-Description">
            {messages[locale].copyright.replace(/\{\{\s*currentYear\s*\}\}/i, currentYear)}
            </div>
        </div>
    </footer>
);

export default FooterNotFound;
