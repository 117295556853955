import React from "react"
import PropTypes from "prop-types"

import HeaderNotFound from "./header/HeaderNotFound"
import "../styles/index.scss"
import IconsSprite from "../components/icons/IconsSprite";
import FooterNotFound from "./footer/FooterNotFound";
import Cookie from "../components/Cookie";
import { Helmet } from "react-helmet";

const LayoutNotFound = ({children, locale}) => {
    return (
        <div id="notFoundPage">
            <Helmet>
                <meta name="yandex-verification" content="7b62d6152cf39ea6" />
            </Helmet>
            <IconsSprite />
            <div id="main">
                <HeaderNotFound locale={locale}/>
                <div className="Main">
                    {children}
                </div>
                <FooterNotFound locale={locale}/>
            </div>
            <Cookie locale={locale}/>
        </div>
    )
};

LayoutNotFound.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LayoutNotFound
