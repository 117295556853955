import React from "react"

import LayoutNotFound from "../layout/LayoutNotFound"
import SEO from "../components/seo"
import Title from "../components/typography/Title";

import enMessages from '../i18n/404/en.json'
import ruMessages from '../i18n/404/ru.json'
import TextButton from "../components/TextButton";
import useLanguage from "../hooks/useLanguage";

const messages = {
    en: enMessages,
    ru: ruMessages
}

const NotFoundPage = ({ pathContext: { locale } }) => {
    const [language] = useLanguage()

    return <LayoutNotFound locale={language}>
        <SEO title="404: Not found"/>
        <div className="container">
            <Title className="Block-Title" level="h1" headerStyleNumb="6">404</Title>
            <div
                style={{maxWidth: '415px'}}
                className="Block-Text text5"
                dangerouslySetInnerHTML={{__html: messages[language].description}}
            />

            <div className="Block-Links Block-Links-404">
                {messages[language].listLinks.map(link => (
                    <div>
                        <TextButton
                            className={'TextButton_padding_small'}
                            size={'medium'}
                            to={link.link}
                            iconId="ArrowRight"
                            styleTheme="goTo"
                            iconPosition={'before'}
                        >
                            {link.text}
                        </TextButton>
                    </div>
                ))}

                <div className={'Block-Text text6'}>
                    {messages[language].buttonHome.text} <TextButton
                    className={'TextButton_padding_small'}
                    size={'medium'}
                    to={'/'}
                    styleTheme="goTo"
                    iconPosition={'before'}
                >
                    {messages[language].buttonHome.buttonText}
                </TextButton>
                </div>
            </div>
        </div>
    </LayoutNotFound>
}

export default NotFoundPage
