import React from "react";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import RedLogo from "../../components/RedLogo";
import YandexMetrika from "../../components/YandexMetrika";

import '../../styles/layout/header.scss';

const HeaderNotFound = ({locale}) => {
    return (
        <React.Fragment>
            <header className="Header">
                <div className="container">
                    <div className="Header-Inner">
                        <div className="Header-Cell Header-Cell_logo">
                            <RedLogo />
                        </div>
                        <div className="Header-Cell Header-Cell_languages Header-Cell_languages-NotFound">
                            <LanguageSwitcher />
                        </div>
                    </div>
                </div>
            </header>
            <YandexMetrika />
        </React.Fragment>
    )
};

export default HeaderNotFound
